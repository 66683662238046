import { APICore } from './apiCore';

const api = new APICore();

function getUpdate(params: any): any {
    const baseUrl = '/update/get';
    return api.get(`${baseUrl}`, params);
}

function updateUpdate(params: any): any {
    const baseUrl = 'update/update';
    return api.update(`${baseUrl}`, params);
}

function updateList(params: any): any {
    const baseUrl = '/update/list';
    return api.get(`${baseUrl}`, params);
}

function doneUpdate(params: any): any {
    const baseUrl = 'update/done';
    return api.update(`${baseUrl}`, params);
}

function updateAnalyst(params: any): any {
    const baseUrl = 'update/update_analyst';
    return api.update(`${baseUrl}`, params);
}

function HitUrgent(params: any): any {
    const baseUrl = 'update/hit_urgent';
    return api.update(`${baseUrl}`, params);
}

function updateImage(params) {
    const baseUrl = 'update_image/upload';
    return api.createWithFile(`${baseUrl}`, params);
}

function serveUpdateImage(params) {
    const baseUrl = 'update_image/serve_img';
    return api.get(`${baseUrl}`, params);
}

function serveImageFile(params) {
    const baseUrl = 'update_image/serve_img';
    return api.getFile(`${baseUrl}`, params);
}

function getImageName(params) {
    const baseUrl = 'update_image/image';
    return api.getFile(`${baseUrl}`, params);
}

function getPicTask(team_id, params) {
    const baseUrl = '/update/list_task_pic/' + team_id;
    return api.get(`${baseUrl}`, params);
}

function detailTaskPIC(team_id, params) {
    const baseUrl = '/update/detail_task_pic/' + team_id;
    return api.get(`${baseUrl}`, params);
}

export {
    detailTaskPIC,
    getPicTask,
    getUpdate,
    updateUpdate,
    updateList,
    doneUpdate,
    updateAnalyst,
    HitUrgent,
    updateImage,
    serveUpdateImage,
    serveImageFile,
    getImageName,
};
