// @flow
import { UserActionTypes } from './constans'

type UserAction = { type: string, payload: {} | string };

// common success
export const userApiResponseSuccess = (actionType: string, data: any): UserAction => ({
    type: UserActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const userApiResponseError = (actionType: string, error: string): UserAction => ({
    type: UserActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getUserList = (params): UserAction => ({
    type: UserActionTypes.GET_USER_LIST ,
    payload: { params },
});

export const getUserId = (user_id): UserAction => ({
    type: UserActionTypes.GET_USER_ID,
    payload: { user_id },
});

export const addUser = (fullname, email, username, password, user_type, hospital_id): UserAction =>
({
    type: UserActionTypes.ADD_USER,
    payload: { fullname, email, username, password, user_type, hospital_id },
});

export const updateUser = (user_id, fullname, email, username, user_type, hospital_id): UserAction => ({
    type: UserActionTypes.UPDATE_USER,
    payload: { user_id, fullname, email, username, user_type, hospital_id},
});

export const deleteUser = (user_id: Number): UserAction => ({
    type: UserActionTypes.DELETE_USER,
    payload: user_id,
});

export const backResetStateUser = (): UserAction => ({
    type: UserActionTypes.BACK_RESET_STATE_USER,
    payload: {},
});

export const resetUser = (): UserAction => ({
    type: UserActionTypes.RESET_USER,
    payload: {},
});