// @flow
import { APICore } from './apiCore';

const api = new APICore();

// product
function productGetList(params: any): any {
    const baseUrl = '/product/list';
    return api.get(`${baseUrl}`, params);
}

function productGetById(params: any): any {
    const baseUrl = '/product/get';
    return api.get(`${baseUrl}`, params);
}

function productAdd(params: any): any {
    const baseUrl = '/product/add';
    return api.createWithFile(`${baseUrl}`, params);
}

function productUpdate(params: any): any {
    const baseUrl = '/product/update';
    return api.updateWithFile(`${baseUrl}`, params);
}

function productDelete(params: any): any {
    const baseUrl = '/product/delete';
    return api.delete(`${baseUrl}/${params}`);
}

export { productGetList, productGetById, productAdd, productUpdate, productDelete };
