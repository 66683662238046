// @flow
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Hospital from './hospital/reducers'
import Team from './team/reducers'
import Product from './product/reducers'
import RequestFeature from './requestFeature/reducers'
import User from './user/reducers'
import Menu from './menu/reducers'
import Privillege from './privillege/reducers'

export default (combineReducers({
    Auth,
    Layout,
    Hospital,
    Team,
    Product,
    RequestFeature,
    User,
    Menu,
    Privillege
}));
