// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    teamGetList as teamGetListApi,
    teamGetById as teamGetByIdApi,
    teamAdd as teamAddApi,
    teamUpdate as teamUpdateApi,
    teamDelete as teamDeleteApi
} from '../../helpers/';

import { teamApiResponseSuccess, teamApiResponseError } from './actions';
import { TeamActionTypes } from './constants';

/**
 * Get list hospital
 * @param {*} payload - 
 * @query - page, [team_name, team_role]
 */
function* teamList({ payload }) {
    try {
        const response = yield call(teamGetListApi, payload.params);
        yield put(teamApiResponseSuccess(TeamActionTypes.GET_TEAM_LIST, response.data));
    } catch (error) {
        yield put(teamApiResponseError(TeamActionTypes.GET_TEAM_LIST, error));
    }
}

function* teamById({ payload }) {
    try {
        const response = yield call(teamGetByIdApi, payload);
        yield put(teamApiResponseSuccess(TeamActionTypes.GET_TEAM_ID, response.data));
    } catch (error) {
        yield put(teamApiResponseError(TeamActionTypes.GET_TEAM_ID, error));
    }
}

function* teamAdd({ payload }) {
    try {
        const response = yield call(teamAddApi, payload);
        yield put(teamApiResponseSuccess(TeamActionTypes.ADD_TEAM, response.data));
    } catch (error) {
        yield put(teamApiResponseError(TeamActionTypes.ADD_TEAM, error));
    }
}

function* teamUpdate({ payload }) {
    try {
        const response = yield call(teamUpdateApi, payload);
        yield put(teamApiResponseSuccess(TeamActionTypes.UPDATE_TEAM, response.data));
    } catch (error) {
        yield put(teamApiResponseError(TeamActionTypes.UPDATE_TEAM, error));
    }
}

function* teamDelete({ payload }) {
    try {
        const response = yield call(teamDeleteApi, payload);
        yield put(teamApiResponseSuccess(TeamActionTypes.DELETE_TEAM, response.data));
    } catch (error) {
        yield put(teamApiResponseError(TeamActionTypes.DELETE_TEAM, error));
    }
}

export function* watchTeamList(): any {
    yield takeEvery(TeamActionTypes.GET_TEAM_LIST, teamList);
}
export function* watchTeamById(): any {
    yield takeEvery(TeamActionTypes.GET_TEAM_ID, teamById);
}
export function* watchTeamAdd(): any {
    yield takeEvery(TeamActionTypes.ADD_TEAM, teamAdd);
}
export function* watchTeamUpdate(): any {
    yield takeEvery(TeamActionTypes.UPDATE_TEAM, teamUpdate);
}
export function* watchTeamDelete(): any {
    yield takeEvery(TeamActionTypes.DELETE_TEAM, teamDelete);
}

function* teamSaga(): any {
    yield all([
        fork(watchTeamList),
        fork(watchTeamById),
        fork(watchTeamAdd),
        fork(watchTeamUpdate),
        fork(watchTeamDelete),
    ]);
}

export default teamSaga;