// @flow
import { RequestFeatureActionTypes } from './constants';

type RequestFeatureAction = { type: string, payload: {} | string };

// common success
export const requestFeatureApiResponseSuccess = (actionType: string, data: any): RequestFeatureAction => ({
    type: RequestFeatureActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const requestFeatureApiResponseError = (actionType: string, error: string): RequestFeatureAction => ({
    type: RequestFeatureActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getRequestFeatureList = (params): RequestFeatureAction => ({
    type: RequestFeatureActionTypes.GET_REQUEST_FEATURE_LIST,
    payload: { params },
});

export const getRequestFeatureId = (request_uuid): RequestFeatureAction => ({
    type: RequestFeatureActionTypes.GET_REQUEST_FEATURE_ID,
    payload: { request_uuid },
});

export const addRequestFeature = (
    request_title,
    content,
    user_id,
    hospital_id,
    product_id,
    images,
    request_type
): RequestFeatureAction => ({
    type: RequestFeatureActionTypes.ADD_REQUEST_FEATURE,
    payload: { request_title, product_id, content, user_id, hospital_id, images, request_type },
});

export const updateRequestFeature = (
    request_id,
    request_uuid,
    request_title,
    content,
    product_id,
    status,
    hospital_id,
    user_id,
    rejection_solution,
    reason_to_consider,
    images,
    request_type
): RequestFeatureAction => ({
    type: RequestFeatureActionTypes.UPDATE_REQUEST_FEATURE,
    payload: {
        request_id,
        request_uuid,
        request_title,
        content,
        reason_to_consider,
        product_id,
        status,
        hospital_id,
        user_id,
        rejection_solution,
        images,
        request_type,
    },
});

export const deleteRequestFeature = (request_feature_id: Number): RequestFeatureAction => ({
    type: RequestFeatureActionTypes.DELETE_REQUEST_FEATURE,
    payload: request_feature_id,
});

export const backResetStateRequestFeature = (): RequestFeatureAction => ({
    type: RequestFeatureActionTypes.BACK_RESET_STATE_REQUEST_FEATURE,
    payload: {},
});

export const resetRequestFeature = (): RequestFeatureAction => ({
    type: RequestFeatureActionTypes.RESET_REQUEST_FEATURE,
    payload: {},
});
