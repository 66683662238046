// @flow
import { APICore } from './apiCore';

const api = new APICore();

// team
function teamGetList(params: any): any {
    const baseUrl = 'team/list';
    return api.get(`${baseUrl}`, params);
}

function teamGetById(params: any): any {
    const baseUrl = 'team/get';
    return api.get(`${baseUrl}`, params);
}

function teamAdd(params: any): any {
    const baseUrl = 'team/add';
    return api.create(`${baseUrl}`, params);
}

function teamUpdate(params: any): any {
    const baseUrl = 'team/update';
    return api.update(`${baseUrl}`, params);
}

function teamDelete(params: any): any {
    const baseUrl = 'team/delete';
    return api.delete(`${baseUrl}/${params}`);
}

function TeamDashboard(team_id, params): any {
    const baseUrl = '/team/dashboard/' + team_id;
    return api.get(`${baseUrl}`, params);
}

export { teamGetList, teamGetById, teamAdd, teamUpdate, teamDelete, TeamDashboard };
