import { AnimatePresence } from 'framer-motion';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AllRoutes } from './index';

const Routes = () => {
    return (
        <HelmetProvider>
            <BrowserRouter>
                <AnimatePresence>
                    <AllRoutes />
                </AnimatePresence>
            </BrowserRouter>
        </HelmetProvider>
    );
};

export default Routes;
