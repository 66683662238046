// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    hospitalGetList as hospitalGetListApi,
    hospitalGetById as hospitalGetByIdApi,
    hospitalAdd as hospitalAddApi,
    hospitalUpdate as hospitalUpdateApi,
    hospitalDelete as hospitalDeleteApi
} from '../../helpers/';

import { APICore } from '../../helpers/api/apiCore';
import { hospitalApiResponseSuccess, hospitalApiResponseError } from './actions';
import { HospitalActionTypes } from './constants';

const api = new APICore();

/**
 * Get list hospital
 * @param {*} payload - 
 * @query - page, [hospital_name]
 */
function* hospitalList({ payload }) {
    try {
        const response = yield call(hospitalGetListApi, payload.params);
        yield put(hospitalApiResponseSuccess(HospitalActionTypes.GET_HOSPITAL_LIST, response.data));
    } catch (error) {
        yield put(hospitalApiResponseError(HospitalActionTypes.GET_HOSPITAL_LIST, error));
    }
}

function* hospitalById({ payload }) {
    try {
        const response = yield call(hospitalGetByIdApi, payload);
        yield put(hospitalApiResponseSuccess(HospitalActionTypes.GET_HOSPITAL_ID, response.data));
    } catch (error) {
        yield put(hospitalApiResponseError(HospitalActionTypes.GET_HOSPITAL_ID, error));
    }
}

function* hospitalAdd({ payload }) {
    try {
        const response = yield call(hospitalAddApi, payload);
        yield put(hospitalApiResponseSuccess(HospitalActionTypes.ADD_HOSPITAL, response.data));
    } catch (error) {
        yield put(hospitalApiResponseError(HospitalActionTypes.ADD_HOSPITAL, error));
    }
}

function* hospitalUpdate({ payload }) {
    try {
        const response = yield call(hospitalUpdateApi, payload);
        yield put(hospitalApiResponseSuccess(HospitalActionTypes.UPDATE_HOSPITAL, response.data));
    } catch (error) {
        yield put(hospitalApiResponseError(HospitalActionTypes.UPDATE_HOSPITAL, error));
    }
}

function* hospitalDelete({payload}) {
    try {
        const response = yield call(hospitalDeleteApi, payload);
        yield put(hospitalApiResponseSuccess(HospitalActionTypes.DELETE_HOSPITAL, response.data));
    } catch (error) {
        yield put(hospitalApiResponseError(HospitalActionTypes.DELETE_HOSPITAL, error));
    }
}

export function* watchHospitalList() {
    yield takeEvery(HospitalActionTypes.GET_HOSPITAL_LIST, hospitalList);
}

export function* watchHospitalById() {
    yield takeEvery(HospitalActionTypes.GET_HOSPITAL_ID, hospitalById);
}

export function* watchHospitalAdd() {
    yield takeEvery(HospitalActionTypes.ADD_HOSPITAL, hospitalAdd);
}

export function* watchHospitalUpdate() {
    yield takeEvery(HospitalActionTypes.UPDATE_HOSPITAL, hospitalUpdate);
}

export function* watchHospitalDelete() {
    yield takeEvery(HospitalActionTypes.DELETE_HOSPITAL, hospitalDelete);
}

function* hospitalSaga() {
    yield all([
        fork(watchHospitalList),
        fork(watchHospitalById),
        fork(watchHospitalAdd),
        fork(watchHospitalUpdate),
        fork(watchHospitalDelete)
    ]);
}

export default hospitalSaga;