// @flow
import { APICore } from './apiCore';

const api = new APICore();

// hospital
function hospitalGetList(params: any): any {
    const baseUrl = '/hospital/list';
    return api.get(`${baseUrl}`, params);
}

function hospitalGetById(params: any): any {
    const baseUrl = '/hospital/get';
    return api.get(`${baseUrl}`, params)
}

function hospitalAdd(params: any): any {
    const baseUrl = '/hospital/add';
    return api.create(`${baseUrl}`, params);
}

function hospitalUpdate(params: any): any {
    const baseUrl = '/hospital/update';
    return api.update(`${baseUrl}`, params);
}

function hospitalDelete(params: any): any {
    // console.log(params)
    const baseUrl = '/hospital/delete';
    return api.delete(`${baseUrl}/${params}`);
}

export { hospitalGetList, hospitalGetById, hospitalAdd, hospitalUpdate, hospitalDelete };
