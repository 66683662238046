// @flow
import { RequestFeatureActionTypes } from './constants';

const INIT_STATE = {
    requestFeatureList: [],
    loading: true,
};

const RequestFeature = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RequestFeatureActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case RequestFeatureActionTypes.GET_REQUEST_FEATURE_LIST: {
                    return {
                        ...state,
                        requestFeatureList: action.payload.data.data,
                        loading: false,
                        totalData: action.payload.data.total,
                    };
                }
                case RequestFeatureActionTypes.GET_REQUEST_FEATURE_ID: {
                    return {
                        ...state,
                        requestFeature: action.payload.data.data,
                        loading: false,
                    };
                }
                case RequestFeatureActionTypes.ADD_REQUEST_FEATURE: {
                    return {
                        ...state,
                        loading: false,
                        requestFeatureUpdated: true,
                    };
                }
                case RequestFeatureActionTypes.UPDATE_REQUEST_FEATURE: {
                    return {
                        ...state,
                        loading: false,
                        requestFeatureUpdated: true,
                    };
                }
                default:
                    return { ...state };
            }

        case RequestFeatureActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case RequestFeatureActionTypes.GET_REQUEST_FEATURE_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case RequestFeatureActionTypes.GET_REQUEST_FEATURE_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case RequestFeatureActionTypes.ADD_REQUEST_FEATURE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case RequestFeatureActionTypes.UPDATE_REQUEST_FEATURE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case RequestFeatureActionTypes.DELETE_REQUEST_FEATURE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case RequestFeatureActionTypes.GET_REQUEST_FEATURE_LIST:
            return { ...state, loading: true };
        case RequestFeatureActionTypes.GET_REQUEST_FEATURE_ID:
            return { ...state, loading: true };
        case RequestFeatureActionTypes.ADD_REQUEST_FEATURE:
            return { ...state, loading: true };
        case RequestFeatureActionTypes.UPDATE_REQUEST_FEATURE:
            return { ...state, loading: true };
        case RequestFeatureActionTypes.DELETE_REQUEST_FEATURE:
            return { ...state, loading: true };
        case RequestFeatureActionTypes.BACK_RESET_STATE_REQUEST_FEATURE:
            return {
                ...state,
                requestFeatureUpdated: null,
                error: null,
            };
        case RequestFeatureActionTypes.RESET_REQUEST_FEATURE:
            return {
                ...state,
                loading: false,
                error: null,
                requestFeatureUpdated: null,
            };
        default:
            return { ...state };
    }
};

export default RequestFeature;
