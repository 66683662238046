// @flow
import { ProductActionTypes } from './constants';

const INIT_STATE = {
    productList: [],
    loading: false
};


const Product = (state = INIT_STATE, action) => {
    // console.log(action);
    switch (action.type) {
        case ProductActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProductActionTypes.GET_PRODUCT_LIST: {
                    return {
                        ...state,
                        productList: action.payload.data.data,
                        totalData: action.payload.data.total,
                        loading: false
                        
                    };
                }
                case ProductActionTypes.GET_PRODUCT_ID: {
                    return {
                        ...state,
                        product: action.payload.data.data,
                        loading: false,
                    };
                }
                case ProductActionTypes.ADD_PRODUCT: {
                    return {
                        ...state,
                        loading: false,
                        productUpdated: true
                    };
                }
                case ProductActionTypes.UPDATE_PRODUCT: {
                    return {
                        ...state,
                        loading: false,
                        productUpdated: true
                    };
                }
                case ProductActionTypes.DELETE_PRODUCT: {
                    return {
                        ...state,
                        loading: false,
                        productUpdated: true
                    };
                }
                default:
                    return { ...state };
            }

        case ProductActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProductActionTypes.GET_PRODUCT_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ProductActionTypes.GET_PRODUCT_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ProductActionTypes.ADD_PRODUCT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    }
                }
                case ProductActionTypes.UPDATE_PRODUCT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    }
                }
                case ProductActionTypes.DELETE_PRODUCT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };
                }
                default:
                    return { ...state };
            }

        case ProductActionTypes.GET_PRODUCT_LIST:
            return { ...state, loading: true };
        case ProductActionTypes.GET_PRODUCT_ID:
            return { ...state, loading: true };
        case ProductActionTypes.ADD_PRODUCT:
            return { ...state, loading: true }
        case ProductActionTypes.UPDATE_PRODUCT:
            return { ...state, loading: true }
        case ProductActionTypes.DELETE_PRODUCT:
            return { ...state, loading: true }
        case ProductActionTypes.BACK_RESET_STATE_PRODUCT:
            return {
                ...state,
                productUpdated: null,
                error: null,
                product: null
            }
        case ProductActionTypes.RESET_PRODUCT:
            return {
                ...state,
                loading: false,
                error: null,
                product: null,
                productUpdated: null
            }
        default:
            return { ...state };
    }
};

export default Product;
