// @flow
import { MenuActionTypes } from './constants';

const INIT_STATE = {
    menuList: [],
    loading: false
};

const Menu = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MenuActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                
                case MenuActionTypes.GET_MENU_LIST: {
                    
                    return {
                        ...state,
                        menuList: action.payload.data,
                        loading: false,
                    };
                    
                }
                
                default:
                    return { ...state };
            }

        case MenuActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case MenuActionTypes.GET_MENU_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case MenuActionTypes.GET_PRODUCT_LIST:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Menu;
