import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import Cookies from 'js-cookie';
import Login from '../../pages/account/Login';
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;
// const evoluz = axios.create({
//     baseURL: config.API_URL
// });

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // console.log('test', error);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        // window.location.href = '/account/login';
        // const navigate = useNavigate();
        // navigate('/account/login');
        // console.log(response);
        if (error && error.response && error.response.status === 404) {
            // console.log(error.response);
            // window.location.href = '/not-found';
        } else if (error && error.response && error.response.status === 403) {
            console.log(error.response);
            window.location.href = '/access-denied';
        } else {
            // console.log(error);
            switch (error.response.status) {
                case 401:
                    message = error.response.data.message;
                    // console.log(error.response);

                    if (error.response.status === 401) {
                        localStorage.removeItem('evoluz_token');
                        localStorage.removeItem('evoluz_user');
                        Cookies.remove('evoluz_user');
                        sessionStorage.removeItem('evoluz_user');
                        window.location.href = '/account/login';
                        // navigate('login');
                        // <Login />;
                    }
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }

            return Promise.reject(message);
        }
    }
);

const AUTH_SESSION_KEY = 'evoluz_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
    // if (token) axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
    if (token) axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    else delete axios.defaults.headers.common['Authorization'];
};

const getUserFromSession = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const getUserFromLocalStorage = () => {
    const user = localStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

class APICore {
    /**
     * Fetches data from given url
     */
    get = (url, params, auth = null) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params, { headers: { Authorization: 'Bearer ' + auth } });
        } else {
            response = axios.get(`${url}`, params, { headers: { Authorization: 'Bearer ' + auth } });
        }
        return response;
    };

    getFile = (url, params) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls, params) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url, data) => {
        return axios.post(url, data);
    };

    /**
     * Updates patch data
     */
    updatePatch = (url, data) => {
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url, data) => {
        return axios.put(url, data);
    };

    /**
     * Deletes data
     */
    delete = (url) => {
        return axios.delete(url);
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.put(url, formData, config);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();
        return user ? true : false;
        // if (!user || (user && !user.token)) {
        //     return false;
        // }
        // const decoded = jwtDecode(user.token);
        // const currentTime = Date.now() / 1000;
        // if (decoded.exp < currentTime) {
        //     console.warn('access token expired');
        //     return false;
        // } else {
        //     return true;
        // }
    };

    setLoggedInUser = (session) => {
        if (session) {
            sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
            this.setLoggedInUserCookies(session);
        } else {
            sessionStorage.removeItem(AUTH_SESSION_KEY);
            localStorage.removeItem(AUTH_SESSION_KEY);
            localStorage.removeItem('evoluz_token');
            this.setLoggedInUserCookies(null);
        }
    };

    setLoggedInUserLocal = (local) => {
        if (local) {
            localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(local));
        } else {
            localStorage.removeItem(AUTH_SESSION_KEY);
        }
    };

    setLoggedInUserCookies = (session) => {
        if (session) {
            Cookies.set(AUTH_SESSION_KEY, JSON.stringify(session), { expires: 7 });
            // Cookies.set('evoluz_user', JSON.stringify(session), { expires: 7 });
        } else {
            Cookies.remove('evoluz_user');
        }
    };

    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        // return getUserFromSession();
        return getUserFromLocalStorage();
    };

    setUserInSession = (modifiedUser) => {
        let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
        let userInfoLocal = localStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, ...user, ...modifiedUser });
        }
        if (userInfoLocal) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUserLocal({ token, ...user, ...modifiedUser });
        }
    };
}

/*
Check if token available in session
*/
let token = getUserFromLocalStorage();
// console.log('APICORE: ' + localStorage.getItem('evoluz_token'));
if (token) {
    // setAuthorization(JSON.stringify(token));
    setAuthorization(localStorage.getItem('evoluz_token'));
}

export { APICore, setAuthorization, getUserFromSession, getUserFromLocalStorage };
