// @flow
import { ProductActionTypes } from './constants';

// common success
export const productApiResponseSuccess = (actionType, data) => ({
    type: ProductActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const productApiResponseError = (actionType, error) => ({
    type: ProductActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getProductList = (params) => ({
    type: ProductActionTypes.GET_PRODUCT_LIST,
    payload: { params },
});

export const getProductId = (product_id) => ({
    type: ProductActionTypes.GET_PRODUCT_ID,
    payload: { product_id },
});

export const addProduct = (product_name, pic, description = '', picture) => ({
    type: ProductActionTypes.ADD_PRODUCT,
    payload: { product_name, pic, description, picture },
});

export const updateProduct = (product_id, product_name, pic, description = '', picture) => ({
    type: ProductActionTypes.UPDATE_PRODUCT,
    payload: { product_id, product_name, pic, description, picture },
});

export const deleteProduct = (product_id) => ({
    type: ProductActionTypes.DELETE_PRODUCT,
    payload: product_id,
});

export const backResetStateProduct = () => ({
    type: ProductActionTypes.BACK_RESET_STATE_PRODUCT,
    payload: {},
});

export const resetProduct = () => ({
    type: ProductActionTypes.RESET_PRODUCT,
    payload: {},
});
