// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { requestUploadImage } from '../../helpers/api/requestImage';

import {
    requestFeatureGetList as requestFeatureGetListApi,
    requestFeatureGetById as requestFeatureGetByIdApi,
    requestFeatureAdd as requestFeatureAddApi,
    requestFeatureUpdate as requestFeatureUpdateApi,
    requestFeatureDelete as requestFeatureDeleteApi,
} from '../../helpers';

import { requestFeatureApiResponseSuccess, requestFeatureApiResponseError } from './actions';
import { RequestFeatureActionTypes } from './constants';
import toast from 'react-hot-toast';

/**
 * Get list request feature
 * @param {*} payload -
 * @query - page, [product_name]
 */
function* requestFeatureList({ payload }) {
    try {
        // console.log(payload);
        const response = yield call(requestFeatureGetListApi, payload.params);
        // console.log(response);
        yield put(requestFeatureApiResponseSuccess(RequestFeatureActionTypes.GET_REQUEST_FEATURE_LIST, response.data));
    } catch (error) {
        yield put(requestFeatureApiResponseError(RequestFeatureActionTypes.GET_REQUEST_FEATURE_LIST, error));
    }
}

function* requestFeatureById({ payload }) {
    try {
        const response = yield call(requestFeatureGetByIdApi, payload);
        yield put(requestFeatureApiResponseSuccess(RequestFeatureActionTypes.GET_REQUEST_FEATURE_ID, response.data));
    } catch (error) {
        yield put(requestFeatureApiResponseError(RequestFeatureActionTypes.GET_REQUEST_FEATURE_ID, error));
    }
}

function* requestFeatureAdd({ payload }) {
    try {
        const response = yield call(requestFeatureAddApi, payload);
        if (payload.images && payload.images.length > 0) {
            payload.images.forEach((image) => {
                requestUploadImage({ picture: image, request_id: response.data.data.request_id });
            });
        }

        // if(payload.images){
        //     // eslint-disable-next-line no-sequences
        //     requestUploadImage({"picture":  payload.images[0] , "request_id" : response.data.data.request_id})
        // }
        // console.log(payload)
        yield put(requestFeatureApiResponseSuccess(RequestFeatureActionTypes.ADD_REQUEST_FEATURE, response.data));
        // console.log(response);
        // console.log(response.data);
    } catch (error) {
        yield put(requestFeatureApiResponseError(RequestFeatureActionTypes.ADD_REQUEST_FEATURE, error));
    }
}

function* requestFeatureUpdate({ payload }) {
    try {
        const response = yield call(requestFeatureUpdateApi, payload);
        // console.log(response);
        // console.log(payload);
        if (response === 200) {
            toast.success(response.data.message[0]);
        }
        if (payload.images && payload.images.length > 0) {
            payload.images.forEach((image) => {
                requestUploadImage({ picture: image, request_id: response.data.data.request_id });
            });
        }
        yield put(requestFeatureApiResponseSuccess(RequestFeatureActionTypes.UPDATE_REQUEST_FEATURE, response.data));
        // console.log(payload.requst_id);
    } catch (error) {
        yield put(requestFeatureApiResponseError(RequestFeatureActionTypes.UPDATE_REQUEST_FEATURE, error));
    }
}

function* requestFeatureDelete({ payload }) {
    try {
        const response = yield call(requestFeatureDeleteApi, payload);
        yield put(requestFeatureApiResponseSuccess(RequestFeatureActionTypes.DELETE_REQUEST_FEATURE, response.data));
    } catch (error) {
        yield put(requestFeatureApiResponseError(RequestFeatureActionTypes.DELETE_REQUEST_FEATURE, error));
    }
}

export function* watchRequestFeatureList(): any {
    yield takeEvery(RequestFeatureActionTypes.GET_REQUEST_FEATURE_LIST, requestFeatureList);
}
export function* watchRequestFeatureById(): any {
    yield takeEvery(RequestFeatureActionTypes.GET_REQUEST_FEATURE_ID, requestFeatureById);
}
export function* watchRequestFeatureAdd(): any {
    yield takeEvery(RequestFeatureActionTypes.ADD_REQUEST_FEATURE, requestFeatureAdd);
}
export function* watchRequestFeatureUpdate(): any {
    yield takeEvery(RequestFeatureActionTypes.UPDATE_REQUEST_FEATURE, requestFeatureUpdate);
}
export function* watchRequestFeatureDelete(): any {
    yield takeEvery(RequestFeatureActionTypes.DELETE_REQUEST_FEATURE, requestFeatureDelete);
}

function* requestFeatureSaga(): any {
    yield all([
        fork(watchRequestFeatureList),
        fork(watchRequestFeatureById),
        fork(watchRequestFeatureAdd),
        fork(watchRequestFeatureUpdate),
        fork(watchRequestFeatureDelete),
    ]);
}

export default requestFeatureSaga;
