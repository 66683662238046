export const UserActionTypes = {
    API_RESPONSE_SUCCESS: '@user/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@user/API_RESPONSE_ERROR',

    GET_USER_LIST: '@user/GET_USER_LIST',
    GET_USER_ID: '@user/GET_USER_ID',
    ADD_USER: '@user/ADD_USER',
    UPDATE_USER: '@user/UPDATE_USER',
    DELETE_USER: '@user/DELETE_USER',
    BACK_RESET_STATE_USER: '@user/BACK_RESET_STATE_USER',
    RESET_USER: '@user/RESET_USER'
};
