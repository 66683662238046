// @flow
import { APICore } from './apiCore';

const api = new APICore();

// privilege
function privillegeListByGroup(params) {
    const baseUrl = 'privillege/listByGroup';
    return api.get(`${baseUrl}`, params);
}

function privillegeGetList(params) {
    const baseUrl = 'privillege/list';
    return api.get(`${baseUrl}`, params);
}

function privillegeMenuList(params) {
    // console.log(params.user_type);
    const baseUrl = 'privilege/listMenuPrivilege';
    return api.get(`${baseUrl}`, params);
}

function privillegeUpdate(params) {
    const baseUrl = 'privilege/update';
    return api.update(`${baseUrl}`, params);
}

function userTypeGet(params) {
    const baseUrl = 'sqlType/userType';
    return api.get(`${baseUrl}`, params);
}

export { privillegeListByGroup, privillegeMenuList, privillegeGetList, privillegeUpdate, userTypeGet };
