import { APICore } from './apiCore';

const api = new APICore();

function startTaskAdd(params) {
    const baseUrl = '/update_team/schedule_start_task';
    return api.create(`${baseUrl}`, params);
}

function teamDetailList(team_id, params) {
    const baseUrl = '/update_team/list_with_update/' + team_id;
    return api.get(`${baseUrl}`, params);
}

function addEstimation(params) {
    const baseUrl = '/update_team/estimation_update';
    return api.update(`${baseUrl}`, params);
}

function finishTask(params) {
    const baseUrl = '/update_team/schedule_end_task';
    return api.create(`${baseUrl}`, params);
}

export { startTaskAdd, teamDetailList, addEstimation, finishTask };
