// @flow
import { APICore, getUserFromLocalStorage, getUserFromSession } from './apiCore';

const api = new APICore();

// requestFeature
function requestFeatureGetList(params) {
    const baseUrl = '/request/list';

    let userSession = getUserFromLocalStorage();
    // console.log(userSession);
    if (userSession.user_type.toLowerCase() !== 'admin') {
        params['hospital_id'] = userSession.hospital.hospital_id;
    }

    return api.get(`${baseUrl}`, params);
}

function requestFeatureGetById(params) {
    const baseUrl = '/request/get_by_uuid';
    return api.get(`${baseUrl}`, params);
}

function requestFeatureAdd(params) {
    const baseUrl = '/request/add';
    return api.create(`${baseUrl}`, params);
}

function requestFeatureUpdate(params) {
    const baseUrl = '/request/update';
    return api.update(`${baseUrl}`, params);
}

function requestFeatureDelete(params) {
    const baseUrl = '/request/delete';
    return api.delete(`${baseUrl}/${params}`);
}

function checkQoutaRequest(params) {
    const baseUrl = '/request/check_quota';
    return api.get(`${baseUrl}`, params);
}

function cancelRequest(params) {
    const baseUrl = '/request/cancel';
    return api.update(`${baseUrl}`, params);
}

export {
    requestFeatureGetList,
    requestFeatureGetById,
    requestFeatureAdd,
    requestFeatureUpdate,
    requestFeatureDelete,
    checkQoutaRequest,
    cancelRequest,
};
