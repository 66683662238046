// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    productGetList as productGetListApi,
    productGetById as productGetByIdApi,
    productAdd as productAddApi,
    productUpdate as productUpdateApi,
    productDelete as productDeleteApi
} from '../../helpers';

import { productApiResponseSuccess, productApiResponseError } from './actions';
import { ProductActionTypes } from './constants';

/**
 * Get list hospital
 * @param {*} payload - 
 * @query - page, [product_name]
 */
function* productList({ payload }) {
    try {
        const response = yield call(productGetListApi, payload.params);
        yield put(productApiResponseSuccess(ProductActionTypes.GET_PRODUCT_LIST, response.data));
    } catch (error) {
        yield put(productApiResponseError(ProductActionTypes.GET_PRODUCT_LIST, error));
    }
}

function* productById({ payload }) {
    try {
        const response = yield call(productGetByIdApi, payload);
        yield put(productApiResponseSuccess(ProductActionTypes.GET_PRODUCT_ID, response.data));
    } catch (error) {
        yield put(productApiResponseError(ProductActionTypes.GET_PRODUCT_ID, error));
    }
}

function* productAdd({ payload }) {
    try {
        const response = yield call(productAddApi, payload);
        yield put(productApiResponseSuccess(ProductActionTypes.ADD_PRODUCT, response.data));
    } catch (error) {
        yield put(productApiResponseError(ProductActionTypes.ADD_PRODUCT, error));
    }
}

function* productUpdate({ payload }) {
    try {
        const response = yield call(productUpdateApi, payload);
        yield put(productApiResponseSuccess(ProductActionTypes.UPDATE_PRODUCT, response.data));
    } catch (error) {
        yield put(productApiResponseError(ProductActionTypes.UPDATE_PRODUCT, error));
    }
}

function* productDelete({ payload }) {
    try {
        const response = yield call(productDeleteApi, payload);
        yield put(productApiResponseSuccess(ProductActionTypes.DELETE_PRODUCT, response.data));
    } catch (error) {
        yield put(productApiResponseError(ProductActionTypes.DELETE_PRODUCT, error));
    }
}

export function* watchProductList(): any {
    yield takeEvery(ProductActionTypes.GET_PRODUCT_LIST, productList);
}
export function* watchProductById(): any {
    yield takeEvery(ProductActionTypes.GET_PRODUCT_ID, productById);
}
export function* watchProductAdd(): any {
    yield takeEvery(ProductActionTypes.ADD_PRODUCT, productAdd);
}
export function* watchProductUpdate(): any {
    yield takeEvery(ProductActionTypes.UPDATE_PRODUCT, productUpdate);
}
export function* watchProductDelete(): any {
    yield takeEvery(ProductActionTypes.DELETE_PRODUCT, productDelete);
}

function* productSaga(): any {
    yield all([
        fork(watchProductList),
        fork(watchProductById),
        fork(watchProductAdd),
        fork(watchProductUpdate),
        fork(watchProductDelete),
    ]);
}

export default productSaga;