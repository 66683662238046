// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    userGetList as userGetListApi,
    userGetById as userGetByIdApi,
    userAdd as userAddApi,
    userUpdate as userUpdateApi,
    userDelete as userDeleteApi
} from '../../helpers/';

import { userApiResponseSuccess, userApiResponseError } from './actions';
import { UserActionTypes } from './constans';

/**
 * Get list hospital
 * @param {*} payload - 
 * @query - page, [team_name, user_role]
 */
function* userList({ payload }) {
    try {
        const response = yield call(userGetListApi, payload.params);
        yield put(userApiResponseSuccess(UserActionTypes.GET_USER_LIST, response.data));
    } catch (error) {
        yield put(userApiResponseError(UserActionTypes.GET_USER_LIST, error));
    }
}

function* userById({ payload }) {
    try {
        const response = yield call(userGetByIdApi, payload);
        yield put(userApiResponseSuccess(UserActionTypes.GET_USER_ID, response.data));
    } catch (error) {
        yield put(userApiResponseError(UserActionTypes.GET_USER_ID, error));
    }
}

function* userAdd({ payload }) {
    try {
        const response = yield call(userAddApi, payload);
        yield put(userApiResponseSuccess(UserActionTypes.ADD_USER, response.data));
    } catch (error) {
        yield put(userApiResponseError(UserActionTypes.ADD_USER, error));
    }
}

function* userUpdate({ payload }) {
    try {
        const response = yield call(userUpdateApi, payload);
        yield put(userApiResponseSuccess(UserActionTypes.UPDATE_USER, response.data));
    } catch (error) {
        yield put(userApiResponseError(UserActionTypes.UPDATE_USER, error));
    }
}

function* userDelete({ payload }) {
    try {
        const response = yield call(userDeleteApi, payload);
        yield put(userApiResponseSuccess(UserActionTypes.DELETE_USER, response.data));
    } catch (error) {
        yield put(userApiResponseError(UserActionTypes.DELETE_USER, error));
    }
}

export function* watchUserList(): any {
    yield takeEvery(UserActionTypes.GET_USER_LIST, userList);
}
export function* watchUserById(): any {
    yield takeEvery(UserActionTypes.GET_USER_ID, userById);
}
export function* watchUserAdd(): any {
    yield takeEvery(UserActionTypes.ADD_USER, userAdd);
}
export function* watchUserUpdate(): any {
    yield takeEvery(UserActionTypes.UPDATE_USER, userUpdate);
}
export function* watchUserDelete(): any {
    yield takeEvery(UserActionTypes.DELETE_USER, userDelete);
}

function* userSaga(): any {
    yield all([
        fork(watchUserList),
        fork(watchUserById),
        fork(watchUserAdd),
        fork(watchUserUpdate),
        fork(watchUserDelete),
    ]);
}

export default userSaga;