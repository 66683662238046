// @flow
import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import hospitalSaga from './hospital/saga';
import teamSaga from './team/saga';
import productSaga from './product/saga';
import requestFeatureSaga from './requestFeature/saga';
import userSaga from './user/saga';
import menuSaga from './menu/saga';
import privillegeSaga from './privillege/saga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        layoutSaga(),
        hospitalSaga(),
        teamSaga(),
        productSaga(),
        requestFeatureSaga(),
        userSaga(),
        menuSaga(),
        privillegeSaga(),
    ]);
}
